import {AnalyticsBrowser} from '@segment/analytics-next';
import {load} from '@fingerprintjs/botd';

import {ObjectOfAny} from '@fo/shared-types';
import {isBrowser} from '@fo/shared/utils/browserSupport';
import getFlags from '@fo/shared-getters/getFlags';

import segmentEvents from './segmentEvents';

export {segmentEvents};

const retryCooldown = 3000;

type IdentifyUser = {
  userId: string;
  email: string;
  name: string;
};

type TUserConsents = {
  consent: {
    categoryPreferences: Window['CookieFirst']['consent']; // globals.d.ts
  };
};

const getUserConsents = (): TUserConsents | null => {
  if (!isBrowser || !window.CookieFirst || window.CookieFirst.consent === null) {
    return null;
  }
  return {
    consent: {
      categoryPreferences: {
        advertising: window.CookieFirst.consent.advertising,
        functional: window.CookieFirst.consent.functional,
        necessary: window.CookieFirst.consent.necessary,
        performance: window.CookieFirst.consent.performance,
      },
    },
  };
};

export const initSegment = async (): Promise<null> => {
  const {SEGMENT_ACTIVE} = getFlags();
  if (!isBrowser || !SEGMENT_ACTIVE) {
    return null;
  }

  window.fundingOptions.segment = new AnalyticsBrowser();

  // Initialize an agent at application startup, once per page/app -> to detect bots.
  const botdPromise = load();
  // Get detection results when you need them.
  botdPromise
    .then((botd) => botd.detect())
    .then((result) => {
      // marks as bot for future reference
      window.fundingOptions.isBot = result.bot;
      // ...and aborts Segment's initialisation
      if (result.bot) {
        return;
      }
      // if not a bot, initilises segment object on window
      // but first wait for user consent response
      const tWaitForConsent = setInterval(() => {
        if (getUserConsents()) {
          window.clearInterval(tWaitForConsent);
          window.fundingOptions.segment?.load({
            writeKey: process.env.SEGMENT_WRITE_KEY || '',
            integrations: {
              'Facebook Pixel': getUserConsents()?.consent.categoryPreferences.advertising,
              'Google Tag Manager': getUserConsents()?.consent.categoryPreferences.advertising,
              'Google Analytics 4 Web': getUserConsents()?.consent.categoryPreferences.performance,
              Hotjar: getUserConsents()?.consent.categoryPreferences.performance,
              Mixpanel: getUserConsents()?.consent.categoryPreferences.performance,
            },
          });
        }
      }, retryCooldown);
    });
  return null;
};

export const identifyUser = ({userId, email, name}: IdentifyUser): void => {
  if (window?.fundingOptions?.segment || window?.fundingOptions?.isBot) {
    return;
  }
  try {
    window?.fundingOptions?.segment?.identify(userId, {
      name,
      email,
    });
  } catch {
    // eslint-disable-next-line no-console
    console.error('Error identifying user in Segment');
  }
};

export const track = (event: string, values: ObjectOfAny = {}): void => {
  if (!window || window?.fundingOptions?.isBot) {
    return;
  }

  const stringfiedValues = JSON.parse(JSON.stringify(values));

  if (!window.fundingOptions?.segment) {
    window.setTimeout(() => {
      track(event, values);
    }, retryCooldown);
    return;
  }
  window.fundingOptions.segment.track(
    event,
    {
      ...stringfiedValues,
      isSegmentEvent: true,
      featureFlags: window?.fundingOptions?.flags,
    },
    {...getUserConsents()}
  );
};

export const logPageVisit = (): void => {
  if (!window || window?.fundingOptions?.isBot) {
    return;
  }
  if (!window.fundingOptions?.segment) {
    window.setTimeout(() => {
      logPageVisit();
    }, retryCooldown);
    return;
  }
  window.fundingOptions.segment.page();
};
